import styles from "../../css/adminLogin.module.scss";
import { observer } from "mobx-react";
import { Input, message } from "antd";
import passwordPrefix from "../../img/passwordPrefix.png";
import accountPrefix from "../../img/accountPrefix.png";
import { userLogin } from "../../utils/request";
import { useState } from "react";
import { useNavigate } from "react-router";

export function AccountPrefix() {
  return (
    <img src={accountPrefix} alt="account" className={styles.accountPrefix} />
  );
}
export function PasswordPrefix() {
  return (
    <img
      src={passwordPrefix}
      alt="password"
      className={styles.passwordPrefix}
    />
  );
}

function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  function doLogin() {
    if (!email || !password) {
      message.warning(
        !email ? "Please enter your email" : "Please enter your password"
      );
      return;
    }
    userLogin({ email, password }).then((res) => {
      if (res.code === 0) {
        if (res.data.isAdmin) {
          navigate("/admin", { replace: true });
        } else {
          message.warning("非管理员");
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  return (
    <div className={styles.page}>
      
      <p>悦读探险家</p>
      <p>后台管理系统</p>


      <Input
        placeholder="账号"
        className={styles.accountInput}
        prefix={<AccountPrefix />}
        value={email}
        onPressEnter={doLogin}
        onChange={(e) => setEmail(e.target.value)}
      ></Input>
      <Input
        placeholder="密码"
        className={styles.passwordInput}
        type="password"
        prefix={<PasswordPrefix />}
        value={password}
        onPressEnter={doLogin}
        onChange={(e) => setPassword(e.target.value)}
      ></Input>
      <div className={styles.loginBtn} onClick={doLogin}>
        登录
      </div>
    </div>
  );
}
export default observer(AdminLogin);
