import { observer } from "mobx-react";
import styles from "../../css/userTrend.module.scss";
import oneSort from "../../img/one.png";
import twoSort from "../../img/two.png";
import threeSort from "../../img/three.png";
import { Select } from "antd";
import { getReal, getProgress, getSemesters } from "../../utils/request";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "../auth";
import { useTranslation } from "react-i18next";

function RenderSort({ index }) {
  if (index > 2) return index;
  if (index === 0) return <img src={oneSort} alt="gold"></img>;
  if (index === 1) return <img src={twoSort} alt="silver"></img>;
  if (index === 2) return <img src={threeSort} alt="copper"></img>;
}

function ArrowIcon() {
  return <span className={styles.arrowIcon}></span>;
}

function RealBoard() {
  const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const getRealLeader = useCallback(() => {
    getReal().then((res) => {
      setDatas(res.data);
    });
  }, []);
  useEffect(() => {
    getRealLeader();
  }, [getRealLeader]);
  return (
    <>
      <div className={styles.realTableHead}>
        <div>{t('ranking')}</div>
        <div>{t('name')}</div>
        <div className={styles.classes}>{t('class')}</div>
        <div>{t('totalScore')}</div>
        <div>{t('bookCount')}</div>
        <div>{t('averageScore')}</div>
      </div>

      <div className={styles.realTableBody}>
        {datas.map((k, i) => (
          <div className={styles.realTableTr} key={i}>
            <div>
              <RenderSort index={i}></RenderSort>
            </div>
            <div>{k.user_name}</div>
            <div className={styles.classes}>{k.class_name}</div>
            <div>{k.totalScore}</div>
            <div>{k.count}</div>
            <div>{k.averageScore}</div>
          </div>
        ))}
      </div>
    </>
  );
}

function ProgressBoard({ id }) {
  const { t } = useTranslation();
  const [progressDatas, setProgressDatas] = useState([]);
  const getProgressLeader = useCallback(() => {
    getProgress(id).then((res) => {
      setProgressDatas(res.data);
    });
  }, [id]);
  useEffect(() => {
    getProgressLeader();
  }, [getProgressLeader]);
  return (
    <>
      <div className={styles.progressTableHead}>
        <div>{t('ranking')}</div>
        <div>{t('name')}</div>
        <div className={styles.classes}>{t('class')}</div>
        <div className={styles.mark}>{t('totalScoreIncrease')}</div>
        {/* <div className={styles.mark}>实时进步平均分</div> */}
      </div>
      <div className={styles.progressTableBody}>
        {progressDatas.map((k, i) => (
          <div className={styles.progressTableTr} key={i}>
            <div>
              <RenderSort index={i}></RenderSort>
            </div>
            <div>{k.username}</div>
            <div className={styles.classes}>{k.className}</div>
            <div className={styles.mark}>{k.totalScoreIncrease}</div>
            {/* <div className={styles.mark}>391</div> */}
          </div>
        ))}
      </div>
    </>
  );
}

function UserTrend() {
  const [type, setType] = useState("real");
  const [options, setOptions] = useState([]);
  const [activeSemester, setActiveSemester] = useState();
  const { setHead } = useContext(LayoutContext);
  const { t } = useTranslation();

  const getAllSemesters = useCallback(() => {
    getSemesters().then((res) => {
      if (res.data.length) {
        setOptions([
          { value: "real", label: t('totalRanking') },
          { value: "progress", label: t('progressRanking') },
        ]);
        const progressId = res.data[0].id;
        setActiveSemester(progressId);
      } else {
        setOptions([{ value: "real", label: t('totalRanking') }]);
      }
    });
  }, [t]);

  useEffect(() => {
    getAllSemesters();
    setHead({
      needMenu: true,
    });
  }, [setHead, getAllSemesters]);
  function onTypeChange(e) {
    setType(e);
  }

  return (
    <div className={styles.page}>
      <div className={styles.pageWrap}>
        <div className={styles.leaders}>
          <div className={styles.realLeader}>
            <div className={styles.realTitle}>
              <Select
                value={type}
                onChange={onTypeChange}
                suffixIcon={<ArrowIcon></ArrowIcon>}
                options={options}
              />
            </div>
            {type === "real" ? (
              <RealBoard />
            ) : type === "progress" ? (
              <ProgressBoard id={activeSemester} />
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.pagebg}></div>
    </div>
  );
}
export default observer(UserTrend);
