import { observer } from "mobx-react";
import BookInfo from "./components/bookInfo";
import RenderQuestions from "./components/questions";
import { downloadBlob } from "../../utils/utils";
import { DownloadOutlined } from "@ant-design/icons";
import { Upload, Button, message, Modal, Input } from "antd";
import { submitBook, downloadQuestionTemplate } from "../../utils/request";
import styles from "../../css/adminBookCreate.module.scss";
import { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import AIIcon from "../../img/ai-iocn.png";
import bookStore from "../../store/bookStore";

function AdminBookCreate() {
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      bookStore.getBookInfo(id);
    }
  }, [id]);

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);

  const uploadOptions = {
    name: "file",
    action: "/v1/upload/books",
    showUploadList: false,
    data: {
      type: "books",
    },
    headers: {
      authorization: `Bearer ${token}`,
    },
    beforeUpload(file) {
      const extName = file.name.split(".").pop().toLowerCase();
      const types = ["zip", "xls", "xlsx"];
      if (!types.includes(extName)) {
        message.error(`You must upload xls/xlsx`);
        return false;
      }
      return true;
    },
    onChange(info) {
      if (info.file.status === "done") {
        const responseData = info.file.response.data;
        processResult(responseData);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  function processResult(result) {
    if (result.length > 1) {
      message.success("导入成功");
      return;
    }
    const { bookInfo, questionInfo } = result[0];
    if (bookInfo) {
      bookStore.updateBookInfo(bookInfo);
    }
    if (questionInfo) {
      bookStore.setQuestions(
        questionInfo.map((questionItem, i) => {
          const correctValue = questionItem.options.findIndex(
            (k) => k.isCorrect
          );
          return {
            index: i,
            id: questionItem.id,
            title: questionItem.title,
            remark: questionItem.remark,
            options: questionItem.options,
            correctValue: correctValue,
          };
        })
      );
    }
  }

  function getOptions(item) {
    return item.options.map((k, i) => ({
      ...k,
      isCorrect: item.correctValue === i,
    }));
  }
  function downloadTemp(e) {
    e.stopPropagation();
    downloadQuestionTemplate().then((res) => {
      downloadBlob(res, "题库导入模板");
    });
  }



  async function submit(isDraft = false) {
    // if (!bookStore.validateBookInfo()) return;

    if (!isDraft) {
      const isValid = await bookStore.validateBookInfo()
      if (!isValid) return;
      const questionsValid = await bookStore.validateAllQuestions()
      if (!questionsValid) return;
    }

    const questions = bookStore.questions;

    submitBook({
      id,
      ...bookStore.bookInfo,
      isPublished: !isDraft,
      questions: questions.map((d) => ({
        id: d.id,
        title: d.title,
        remark: d.remark,
        type: "radio",
        options: getOptions(d),
      })),
    }).then(() => {
      message.success(id ? "更新成功" : "保存成功");
      navigate("/admin/book");
    });
  }

  const submitAIContent = () => {
    if (bookStore.aiContent.length < 100) {
      message.error("文案不少于100字");
      return;
    }
    setIsOpen(false);
  }

  return (
    <div className={styles.pageBody}>
      <div className={styles.pageContent}>
        <BookInfo id={id} />
        <div className={styles.pageRight}>
          <RenderQuestions />
        </div>
      </div>
      <div className={styles.pageAction}>
        <div className={styles.uploadAI}>
          <Button className={styles.uploadAIBtn} onClick={() => setIsOpen(true)}>
            上传原文本AI解析
          </Button>
          <text className={styles.uploadAIDesc}>可以上传自己写的书让AI解析</text>
        </div>
        <Upload {...uploadOptions}>
          <div className={styles.uploadBtns}>
            <Button>快速导入excel题库</Button>
            <Button icon={<DownloadOutlined />} onClick={downloadTemp}></Button>
          </div>
        </Upload>
        <Button onClick={() => submit(true)}>保存题目草稿</Button>
        <Button type="primary" onClick={() => submit(false)}>
          保存
        </Button>
      </div>
      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        centered
        width={1504}
        wrapClassName={styles.modal}
      >
        <div className={styles.modalTitleContainer}>
          <img src={AIIcon} className={styles.titleIcon} />
          <text className={styles.modalTitle}>AI智能解析文本模块</text>
          <text className={styles.modalSubTitle}>（请输入文案，不少于100字）</text>
        </div>
        <div className={styles.textWrap}>
          <Input.TextArea
            onChange={(e) => bookStore.setAIContent(e.target.value)}
            value={bookStore.aiContent}
            count={{
              show: true,
            }}
          />
          <Button className={styles.submitBtn} onClick={submitAIContent}>
            确定上传文本AI解析
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default observer(AdminBookCreate);
