import { Tabs } from "antd";
import QuestionItem from "./questionItem";
import { observer } from "mobx-react";
import bookStore from "../../../store/bookStore";

const RenderQuestions = observer(() => {

  const handleEdit = (targetKey, action) => {
    if (action === 'add') {
      bookStore.addQuestion();
    } else if (action === 'remove') {
      bookStore.removeQuestion(targetKey);
    }
  };

  return (
    <Tabs
      type="editable-card"
      activeKey={String(bookStore.activeQuestionKey)}
      items={bookStore.questions.map((item, i) => {
        return {
          label: i < 8 ? `Q${i + 1}*` : `Q${i + 1}`,
          key: i.toString(),
          children: (
            <QuestionItem
              item={item}
              index={i}
            />
          ),
        };
      })}
      onEdit={handleEdit}
      onChange={(key) => bookStore.changeTab(key)}
    />
  );
});

export default RenderQuestions;
