import { makeAutoObservable } from "mobx";
import { create, persist } from 'mobx-persist'
import { message } from "antd";
import { generateQuestion, getBookInfo } from "../utils/request";

// Todo 用户信息
class BookStore {
  loading = false
  questions = Array.from({ length: 8 }, () => ({
    title: "",
    remark: "",
    options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }],
    correctValue: "",
    questionType: "",
  }))
  activeQuestionKey = 0

  questionTypes = [
    { value: 'Information Retrieval 资讯检索' },
    { value: 'Inferences 推论信息' },
    { value: 'Interpretation 解释和整合' },
    { value: 'Evaluation评估' }
  ]

  // 每个问题的加载状态
  questionLoadingStates = Array(12).fill(false)

  // 新增 bookInfo 相关状态
  bookInfo = {
    id: undefined,
    title: "",
    cover: "",
    content: "",
    category: "",
    subCategory: [],
    classes: [],
    filePath: ""
  }

  // AI 文本相关状态
  aiContent = ""

  constructor() {
    makeAutoObservable(this);
  }

  getBookInfo(id) {
    getBookInfo(id).then(res => {
      this.bookInfo = res.data;
    });
  }

  isInvalid(val) {
    return val === undefined || val === null || val === "";
  }

  async validateActiveQuestion() {
    const currentQuestion = this.questions[this.activeQuestionKey];
    if (!currentQuestion) return true;

    if (this.isInvalid(currentQuestion.correctValue)) {
      message.warning("请选择正确答案");
      return false;
    }
    return true;
  }

  validateQuestion(question) {
    if (!question.title) {
      message.warning("题目不可为空");
      return false;
    }
    if (question.options.find(o => !!o.text)) {
      message.warning("选项不可为空");
      return false;
    }
    if (!question.correctValue) {
      message.warning("请选择正确答案");
      return false;
    }
    return true;
  }

  async validateAllQuestions() {
    for (let i = 0; i < this.questions.length; i++) {
      if (!this.validateQuestion(this.questions[i])) {
        this.activeQuestionKey = i;
        return false;
      }
    }
    return true;
  }

  async changeTab(newKey) {
    // const isValid = await this.validateActiveQuestion();
    // if (!isValid) return false;
    this.setActiveKey(newKey);
    return true;
  }

  setQuestions(questions) {
    this.questions = questions;
  }

  setQuestionLoading(index, loading) {
    this.questionLoadingStates[index] = loading;
  }

  // 更新 bookInfo
  updateBookInfo(updates) {
    this.bookInfo = {
      ...this.bookInfo,
      ...updates
    };
  }

  // 设置 AI 内容
  setAIContent(content) {
    this.aiContent = content;
  }

  // 验证 bookInfo
  validateBookInfo() {
    if (!this.bookInfo.cover) {
      message.warning("书籍封面不可为空");
      return false;
    }
    if (!this.bookInfo.title) {
      message.warning("书籍名称不可为空");
      return false;
    }
    if (!this.bookInfo.content) {
      message.warning("书籍内容不可为空");
      return false;
    }
    if (!this.bookInfo.subCategory.length) {
      message.warning("书籍类目不可为空");
      return false;
    }
    if (!this.bookInfo.classes.length) {
      message.warning("书籍可见班级不可为空");
      return false;
    }
    return true;
  }

  // AI 生成问题
  async generateQuestionByAI(index) {
    if (!this.bookInfo.title) {
      message.warning('请先填写书名');
      return;
    }

    if (!this.questions[index].questionType) {
      message.warning('请选择题目类型');
      return;
    }

    try {
      this.setQuestionLoading(index, true);
      const result = await generateQuestion({
        bookId: this.bookInfo.id,
        title: this.bookInfo.title,
        category: this.bookInfo.category,
        filePath: this.bookInfo.filePath,
        content: this.aiContent,
        questionType: this.questions[index].questionType
      });

      if (result) {
        this.updateQuestionField(index, {
          title: result.data.question,
          remark: result.data.explanation,
          options: [
            { text: result.data.answerList[0] },
            { text: result.data.answerList[1] },
            { text: result.data.answerList[2] },
            { text: result.data.answerList[3] }
          ],
          correctValue: result.data.correctAnswerIndex
        });
      }
      return result;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      this.setQuestionLoading(index, false);
    }
  }

  updateQuestionField(index, values) {
    this.questions[index] = {
      ...this.questions[index],
      ...values
    };
  }

  setActiveKey(key) {
    this.activeQuestionKey = Number(key);
  }

  addQuestion() {
    if (this.questions.length >= 12) {
      message.warning('题目数量不能超过12个');
      return;
    }
    this.questions.push({
      title: "",
      remark: "",
      options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }],
      correctValue: "",
      questionType: "",
    });
    this.setActiveKey(this.questions.length - 1);
  }

  removeQuestion(targetKey) {
    if (this.questions.length <= 8) {
      message.warning('题目数量不能少于8个');
      return;
    }

    const targetIndex = Number(targetKey);
    this.questions.splice(targetIndex, 1);

    if (this.activeQuestionKey >= this.questions.length) {
      this.activeQuestionKey = this.questions.length - 1;
    }
  }
}

const schema = {
  profile: 'object'
}


const hydrate = create({
  storage: localStorage,   // or AsyncStorage in react-native.
  jsonify: true  // if you use AsyncStorage, here shoud be true
})

const bookStore = persist(schema)(new BookStore())

hydrate('bookStore', bookStore).then(() => console.log('bookStore has been hydrated'))

export default bookStore;

