import styles from '../../../css/adminBookCreate.module.scss'
import AdminUpload from "../../../components/AdminUpload";
import { useState, useEffect, useCallback } from "react";
import { getCategories, getClasses, getBookInfo } from "../../../utils/request";
import { Row, Col, Input, Select } from "antd";
import { observer } from "mobx-react";
import bookStore from "../../../store/bookStore";

const BookInfo = observer(({ id }) => {
  const [allCategories, setAllCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState();
  const [classes, setClasses] = useState([]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    bookStore.updateBookInfo({
      [name]: value,
    });
  }, []);

  const refreshCategories = useCallback(() => {
    return getCategories().then((res) => {
      setAllCategories(res.data.categories);
      setCategories(
        res.data.categories.map((k) => ({
          label: k.name,
          value: k.name,
        }))
      );
      setActiveCategory(res.data.categories[0].name);
      handleChange({
        target: { name: "category", value: res.data.categories[0].name },
      });
      setSubCategories(
        res.data.categories[0].subcategories.map((k) => ({
          label: k.name,
          value: k.name,
        }))
      );
    });
  }, [handleChange]);

  function refreshClasses() {
    return getClasses().then((res) => {
      setClasses(
        res.data.map((k) => ({
          label: k.name,
          value: k.name,
        }))
      );
    });
  }

  const getBook = useCallback(() => {
    if (!id) return;
    getBookInfo(id).then((res) => {
      const bookInfo = res.data;
      bookStore.updateBookInfo({
        cover: bookInfo.cover,
        title: bookInfo.title,
        content: bookInfo.filePath,
        category: bookInfo.subcategory[0]?.category?.name,
        subCategory: bookInfo.subcategory.map((k) => k.name),
        classes: bookInfo.classes.map((k) => k.name),
      });

      if (bookInfo.questions) {
        bookStore.setQuestions(
          bookInfo.questions.map((questionItem, i) => {
            const correctValue = questionItem.options.findIndex(
              (k) => k.isCorrect
            );
            return {
              index: i,
              id: questionItem.id,
              title: questionItem.title,
              remark: questionItem.remark,
              options: questionItem.options,
              correctValue: correctValue,
            };
          })
        );
      }
    });
  }, [id]);

  useEffect(() => {
    refreshCategories()
      .then(() => refreshClasses())
      .then(() => getBook());
  }, [refreshCategories, getBook]);

  function handleClassChange(e) {
    handleChange({ target: { name: "classes", value: e } });
  }

  function handleCategoryChange(name) {
    setActiveCategory(name);
    const currentCategory = allCategories.find((k) => k.name === name);
    setSubCategories(
      currentCategory.subcategories.map((k) => ({
        label: k.name,
        value: k.name,
      }))
    );
    handleChange({ target: { name: "category", value: name } });
    handleChange({ target: { name: "subCategory", value: [] } });
  }
  function handleSubCategoryChange(e) {
    handleChange({ target: { name: "subCategory", value: e } });
  }

  function onCoverSuccess(e) {
    handleChange({ target: { name: "cover", value: e.path } });
  }
  function onContentSuccess(e) {
    handleChange({ target: { name: "filePath", value: e.path } });
  }

  return (
    <div className={styles.pageLeft}>
      <Row align="middle">
        <Col className="gutter-row" span={3}>
          书籍封面
        </Col>
        <Col className="gutter-row" span={12}>
          <AdminUpload
            types={[".png", ".jpg", ".jpeg"]}
            previewUrl={bookStore.bookInfo.cover}
            onSuccess={onCoverSuccess}
          ></AdminUpload>
        </Col>
      </Row>
      <Row align="middle">
        <Col className="gutter-row" span={3}>
          <div>书籍名称</div>
        </Col>
        <Col className="gutter-row" span={12}>
          <Input
            placeholder="书籍名称"
            name="title"
            value={bookStore.bookInfo.title}
            onChange={handleChange}
          ></Input>
        </Col>
      </Row>
      <Row align="middle">
        <Col className="gutter-row" span={3}>
          <div>书籍内容</div>
        </Col>
        <Col className="gutter-row" span={12}>
          <AdminUpload
            types={[".pdf"]}
            previewUrl={bookStore.bookInfo.content}
            onSuccess={onContentSuccess}
          ></AdminUpload>
        </Col>
      </Row>
      <Row align="middle">
        <Col className="gutter-row" span={3}>
          <div>书籍类目</div>
        </Col>
        <Col className="gutter-row" span={4}>
          <Select
            placeholder="一级类目"
            value={activeCategory}
            onChange={handleCategoryChange}
            options={categories}
          />
        </Col>
        <Col span={8}>
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="书籍类目"
            value={bookStore.bookInfo.subCategory}
            onChange={handleSubCategoryChange}
            options={subCategories}
          />
        </Col>
      </Row>
      <Row align="middle">
        <Col className="gutter-row" span={3}>
          <div>可见班级</div>
        </Col>
        <Col className="gutter-row" span={12}>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="可见班级"
            value={bookStore.bookInfo.classes}
            onChange={handleClassChange}
            options={classes}
          />
        </Col>
      </Row>
    </div>
  );
});
export default BookInfo