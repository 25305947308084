export default {
    "welcome": "欢迎",
    "myCenter": "个人中心",
    "logout": "登出",
    "bookLanguage": "读物语言",
    "bookCategory": "读物分类",
    "feedbackPlaceholder": "同学们好～\n有什么意见都可以反馈给老师哦～\n点此输入",
    "submit": "提交",
    "myAccount": "个人账号",
    "account": "账号",
    "inputAccount": "账号 / Account",
    "inputPassword": "密码 / Password",
    "myBadge": "我的徽章库",
    "changePassword": "修改密码",
    "oldPassword": "输入原密码",
    "newPassword": "输入新密码",
    "confirmChange": "确定修改",
    "name": "名字",
    "class": "班级",
    "login": "登录",
    "loginAccount": "登录账号",
    "ok": "好的",
    "vote": "投票",
    "challenge": "去挑战",
    "allBooks": "全部书",
    "finishedBooks": "已完成书籍{{count}}本",
    "myTotalScore": "我的总分",
    "averageScore": "平均分",
    "answerScore": "答题得分",
    "reflectionScore": "读后感得分",
    "totalRanking": "实时总榜单",
    "progressRanking": "实时进步榜",
    "ranking": "排行",
    "name": "名字",
    "class": "班级",
    "bookCount": "书本数",
    "totalScoreIncrease": "实时进步总分",
    "中文书": "中文书",
    "英文书": "英文书",
    hint: {
        "pleaseInputOldPassword": "请输入旧密码",
        "pleaseInputNewPassword": "请输入新密码",
        "changePasswordSuccess": "修改密码成功",
        "pleaseInputFeedbackContent": "请输入反馈内容",
        "submitSuccess": "提交成功",
        "thankYouForFeedback": "感谢同学的反馈",
        "teacherWillReview": "老师收到后会仔细查看的",
        "noResult": "暂无结果",
        "searchResult": "搜索结果{{count}}本",
        "searchPlaceholder": "搜索书籍",
        "pleaseAnswerCurrentQuestion": "请先回答当前问题",
    },
    userMenu: {
        0: "挑战",
        1: "我的成绩",
        2: "我的排行榜",
        3: "个人中心",
        4: "意见反馈",
    },
    "goRead": "去阅读",
    "challengeTimes": "挑战3次（计算最高分）",
    "challenge2Times": "第二次挑战打9折",
    "challenge3Times": "第三次挑战打8折",
    "startChallenge": "开始挑战",
    "nextQuestion": "下一题",
    "remark": "备注",
    "congratulations": "恭喜你！",
    "congratulationsContent": "完成了《{{bookTitle}}》答题挑战",
    "yourScore": "你的分数是",
    "score": "分",
    "challengeAgain": "再挑战",
    "totalScore": "总分",
    "waitingForScore": "等待老师评分",
    "writeReflection": "写读后感",
    "reflectionTitlePlaceholder": "不超过20字",
    "reflectionContentPlaceholder": "正文不超过1000字",
    "markRatingLabel": "对本书打分",
    "submitReflection": "提交读后感",
    "reflectionScoreIs": "读后感分数是：",
    "winGoldBadge": "获得金色勋章",
    "winSilverBadge": "获得银色勋章",
    "winCopperBadge": "获得铜色勋章",
    "loginLeftContent": "学生们不仅能沉浸在精彩纷呈的故事与知识海洋中，还能在享受阅读乐趣的同时，参与互动问答挑战。",
    "submitSuccess": "提交成功",
}
