import dayjs from "dayjs";
export const columns = [
  {
    title: "名字",
    dataIndex: "userName",
    key: "userName",
  },
  {
    title: "性别",
    dataIndex: "userGender",
    key: "userGender",
  },
  {
    title: "班级",
    dataIndex: "className",
    key: "className",
  },
  {
    title: "邮箱",
    dataIndex: "userEmail",
    key: "userEmail",
  },
  {
    title: "是否提交",
    dataIndex: "surveyBatch",
    key: "surveyBatch",
    render: (_, { surveyBatch }) => <span>{surveyBatch ? "是" : "否"}</span>,
  },
  {
    title: "提交批次",
    dataIndex: "surveyBatch",
    key: "surveyBatch",
  },
  {
    title: "提交时间",
    dataIndex: "answerCreatedAt",
    key: "answerCreatedAt",
    render: (_, { answerCreatedAt }) => (
      <span>
        {!answerCreatedAt
          ? ""
          : dayjs(answerCreatedAt).format("YYYY/MM/DD HH:mm:ss")}
      </span>
    ),
  },
];
