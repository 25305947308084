import { useEffect } from "react";
import { Input, Radio, Form, Select, Button } from "antd";
import styles from "../../../css/adminBookCreate.module.scss";
import { observer } from "mobx-react";
import bookStore from "../../../store/bookStore";

const QuestionItem = observer(({ index }) => {
  const [form] = Form.useForm();
  const item = bookStore.questions[index];

  const onTypeChange = (value) => {
    bookStore.updateQuestionField(index, { questionType: value });
  };

  const onRadioChange = (e) => {
    bookStore.updateQuestionField(index, { correctValue: e.target.value });
  };

  const onValuesChange = (changedValues, allValues) => {
    const options = [
      { text: allValues.optionA || '' },
      { text: allValues.optionB || '' },
      { text: allValues.optionC || '' },
      { text: allValues.optionD || '' }
    ];
    
    bookStore.updateQuestionField(index, {
      title: allValues.title,
      remark: allValues.remark,
      options
    });
  };

  useEffect(() => {
    if (item && item.options) {
      form.setFieldsValue({
        title: item.title || '',
        remark: item.remark || '',
        optionA: item.options[0]?.text || '',
        optionB: item.options[1]?.text || '',
        optionC: item.options[2]?.text || '',
        optionD: item.options[3]?.text || '',
      });
    }
  }, [form, item]);

  return (
    <div>
      <Select
        className={styles.select}
        style={{ width: "300px" }}
        placeholder="AI智能出题选择类型"
        onChange={onTypeChange}
        value={item.questionType}
        options={bookStore.questionTypes}
      />
      <Button 
        type='primary' 
        loading={bookStore.questionLoadingStates[index]} 
        style={{ marginLeft: '10px' }} 
        onClick={() => bookStore.generateQuestionByAI(index)}
      >
        AI生成
      </Button>

      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onValuesChange={onValuesChange}
      >
        <Radio.Group 
          onChange={onRadioChange} 
          value={item.correctValue}
        >
          <div className={styles.itemContent}>
            <Form.Item 
              name="title" 
              label="填写题目" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className={styles.itemContent}>
            <Form.Item 
              name="optionA" 
              label="选项A" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Radio value={0} onChange={(e) => e.stopPropagation()}></Radio>
          </div>

          <div className={styles.itemContent}>
            <Form.Item 
              name="optionB" 
              label="选项B" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Radio value={1} onChange={(e) => e.stopPropagation()}></Radio>
          </div>

          <div className={styles.itemContent}>
            <Form.Item 
              name="optionC" 
              label="选项C" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Radio value={2} onChange={(e) => e.stopPropagation()}></Radio>
          </div>

          <div className={styles.itemContent}>
            <Form.Item 
              name="optionD" 
              label="选项D" 
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Radio value={3} onChange={(e) => e.stopPropagation()}></Radio>
          </div>

          <div className={styles.itemContent}>
            <Form.Item name="remark" label="备注" >
              <Input.TextArea style={{ flex: 1 }} autoSize={{ minRows: 4, maxRows: 9 }} />
            </Form.Item>
          </div>
          
        </Radio.Group>
      </Form>
    </div>
  );
});

export default QuestionItem;
