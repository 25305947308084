import styles from '../../css/adminBookList.module.scss'
import addImg from "../../img/add.png";
import draftImg from "../../img/draft.png";
import { observer } from "mobx-react";
import { Button, Input } from "antd";
import { getCategories, getAdminBooks } from "../../utils/request";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import searchIcon from "../../img/searchIcon.png";
import { LayoutContext } from "../auth";
import { useTranslation } from "react-i18next";

function SearchIcon() {
  return (
    <div className={styles.searchIcon}>
      <img src={searchIcon} alt="search"></img>
    </div>
  );
}

function AdminBookList() {
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [activeLanguage, setActiveLanguage] = useState(-1);
  const [activeSubCategory, setActiveSubCategory] = useState(0);
  const [keyWord, setKeyWord] = useState("");
  const [books, setBooks] = useState([]);
  const [totalBookCount, setTotalBookCount] = useState(0);
  const navigate = useNavigate();
  const { setHead } = useContext(LayoutContext);
  const { t } = useTranslation();

  const updateSubCategories = useCallback(
    (subCategories) => {
      const baseCategories = [
        {
          name: `全部书${totalBookCount}`,
        },
      ];
      setSubCategories(baseCategories.concat(subCategories));
    },
    [totalBookCount]
  );

  const getAllCategories = useCallback(() => {
    getCategories().then((res) => {
      setCategories(res.data.categories);
      setTotalBookCount(res.data.totalBookCount);
      setLanguages([
        {
          name: `全部书${res.data.totalBookCount}`,
          id: -1,
        },
        ...res.data.categories.map((k) => ({
          ...k,
          name: `${t(k.name)}${k.bookCount}`,
        })),
      ]);
      updateSubCategories(getAllSubCategories(res.data.categories));
      getAllBooks(null, "", null);
    });
  }, [updateSubCategories]);

  useEffect(() => {
    getAllCategories();
    setHead({
      title: `题库共${totalBookCount}本书`,
    });
  }, [totalBookCount, setHead, getAllCategories]);

  function getAllBooks(subCategoryId, keyword, categoryId) {
    const category = categoryId === -1 ? null : categoryId;
    getAdminBooks({
      subCategoryId,
      keyword,
      categoryId: category,
    }).then((res) => {
      setBooks(res.data);
    });
  }

  function getAllSubCategories(categories) {
    return categories
      .map((k) => k.subcategories)
      .flat()
      .map((k) => ({
        ...k,
        name: `${k.name}${k.bookCount}`,
      }));
  }

  function onLanguageChange(languageItem, index) {
    setActiveLanguage(languageItem.id);
    const newIdx = index === 0 ? 0 : index - 1;
    const newSubCategories =
      index === 0
        ? getAllSubCategories(categories)
        : categories[newIdx].subcategories;
    updateSubCategories(newSubCategories);
    setActiveSubCategory(0);
    const categoryId = index === 0 ? null : languageItem.id;
    getAllBooks(null, keyWord, categoryId);
  }

  function onSubCategoryChange(index) {
    setActiveSubCategory(index);
    const subCategoryId = index === 0 ? null : subCategories[index].id;
    getAllBooks(subCategoryId, keyWord, activeLanguage);
  }

  function onKeyWordChange(e) {
    setKeyWord(e.target.value);
  }

  function doSearch() {
    const subCategoryId =
      activeSubCategory === 0 ? null : subCategories[activeSubCategory].id;
    getAllBooks(subCategoryId, keyWord, activeLanguage);
  }

  function goReadBook(book) {
    const pdfUrl = encodeURIComponent(book.filePath);
    const url = `https://pdf.happyreading.cc/ebook.html?url=${pdfUrl}`;
    window.open(url, "_blank");
  }

  function goCreate() {
    navigate(`/admin/createbook`);
  }

  function goDraft() {
    navigate(`/admin/drafts/`);
  }

  return (
    <div className={styles.page}>
      <div className={styles.languagesWrap}>
        <div className={styles.languageLeft}>
          <p>读物语言</p>
          <div className={styles.bookLanguages}>
            {languages.map((k, i) => (
              <div
                className={`${styles.languageItem} ${
                  activeLanguage === k.id ? styles.activeLanguageItem : ""
                }`}
                onClick={() => onLanguageChange(k, i)}
                key={i}
              >
                {k.name}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.bookSearch}>
          <Input
            placeholder="搜索书籍"
            value={keyWord}
            onChange={onKeyWordChange}
            onPressEnter={doSearch}
            prefix={<SearchIcon />}
          />
        </div>
      </div>
      <div className={styles.bookCategoriesWrap}>
        <p>读物分类</p>
        <div className={styles.bookCategories}>
          {subCategories.map((k, i) => (
            <div
              className={`${styles.categoryItem} ${
                activeSubCategory === i ? styles.activeCategoryItem : ""
              }`}
              key={i}
              onClick={() => onSubCategoryChange(i)}
            >
              {k.name}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.bookWrap}>
        <div
          className={`${styles.bookItem} ${styles.bookAdd}`}
          onClick={goCreate}
        >
          <img src={addImg} alt="add"></img>
          <span>上传题库</span>
        </div>
        <div
          className={`${styles.bookItem} ${styles.bookDraft}`}
          onClick={goDraft}
        >
          <img src={draftImg} alt="draft"></img>
          <span>草稿箱</span>
        </div>
        {books.map((item) => (
          <div className={styles.bookItem} key={item.id}>
            <div className={styles.bookCover}>
              <img src={item.cover} alt="cover" />
            </div>
            <div className={styles.bookInfo}>
              <p className={styles.bookName}>{item.title}</p>
              <Button
                className={styles.bookPreview}
                onClick={() => goReadBook(item)}
              >
                去预览
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default observer(AdminBookList)