import styles from '../../css/adminBookDrafts.module.scss'
import { Button } from 'antd'
import { observer } from "mobx-react";
import { getDrafts, deleteBook } from "../../utils/request";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "../auth";
import { useNavigate } from "react-router";

function AdminBookDrafts() {
  const [books, setBooks] = useState([]);
  const { setHead } = useContext(LayoutContext);
  const navigate = useNavigate();
  const getBooks = useCallback(() => {
    getDrafts().then((res) => {
      setBooks(res.data);
    });
  }, []);

  useEffect(() => {
    getBooks();
    setHead({
      title: "草稿箱",
    });
  }, [setHead, getBooks]);

  function onEditBook(id) {
    navigate(`/admin/createbook/${id}`);
  }
  
  function onDeleteBook(id) {
    deleteBook(id).then((res) => {
      getBooks();
    });
  }
  return (
    <div className={styles.bookWrap}>
      {
        books.length === 0 && (
          <div className={styles.noBook}>
            暂无草稿
          </div>
        )
      }
      {books.map((item) => (
        <div className={styles.bookItem} key={item.id}>
          <div className={styles.bookCover}>
            <img src={item.cover} alt="cover" />
          </div>
          <div className={styles.bookInfo}>
            <p className={styles.bookName}>{item.title}</p>
            <Button
              type="primary"
              className={styles.bookPreview}
              onClick={() => onEditBook(item.id)}
            >
              继续编辑
            </Button>
            <Button
              className={styles.bookPreview}
              onClick={() => onDeleteBook(item.id)}
            >
              删除
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default observer(AdminBookDrafts)