import { observer } from 'mobx-react';
import { Upload, message } from 'antd'
import { useEffect, useState } from 'react'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import pdfIcon from "../img/pdficon.png";

const UploadButtonStyles = {
  background: "none",
  border: "none",
};

const imgs = ["png", "jpeg", "jpg"];

const uploadWrapStyles = {
  width: "120px",
  height: "160px",
  display: "flex",
  alignItems: "center",
  border: "1px solid #979797",
  cursor: "pointer",
  borderRadius: "8px",
  justifyContent: "center",
};

function AdminUpload({ types, previewUrl, onSuccess }) {
  const token = localStorage.getItem("token");
  const headers = {
    authorization: `Bearer ${token}`,
  };
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    updateIcon(previewUrl);
  }, [previewUrl]);
  function updateIcon(url) {
    if (!url) return;
    const extName = url.split(".").pop().toLowerCase();
    let imgUrl = url;
    if (url && !imgs.includes(extName)) {
      imgUrl = pdfIcon;
    }
    setImageUrl(imgUrl);
  }
  function handleChange(info) {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      const responseData = info.file.response.data;
      setLoading(false);
      updateIcon(responseData.path);
      if (onSuccess) {
        onSuccess(responseData);
      }
    }
  }

  function UploadButton() {
    return (
      <button style={UploadButtonStyles} type="button">
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传</div>
      </button>
    );
  }

  return (
    <Upload
      name="file"
      showUploadList={false}
      action="/v1/upload"
      accept={types.join(",")}
      headers={headers}
      onChange={handleChange}
    >
      <div style={uploadWrapStyles}>
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <UploadButton />
        )}
      </div>
    </Upload>
  );
}
export default observer(AdminUpload)